import {
	rgbHexStringToRgbNumber,
	rgbToRgba,
} from "./utils/colour-conversions.ts";
import { hexStringToBrickColour, type Palette } from "./model/index.ts";

const basePlateHexColour = "#ffffff";

const redMask24 = 0xff0000;
const greenMask24 = 0x00ff00;
const blueMask24 = 0x0000ff;

const basePlateRgb = rgbHexStringToRgbNumber(basePlateHexColour);

const premadeKit = {
	basePlateSize: 24,
	numberOfBasePlates: 4,
	basePlateColour: {
		rgba: rgbToRgba(basePlateRgb, 0xff),
		r: (basePlateRgb & redMask24) >> 16,
		g: (basePlateRgb & greenMask24) >> 8,
		b: basePlateRgb & blueMask24,
	},
	bricks: [
		{
			brick: hexStringToBrickColour("#161616", -1),
			amount: 700,
		},
		{
			brick: hexStringToBrickColour("#F4F4F4", -2),
			amount: 700,
		},
		{
			brick: hexStringToBrickColour("#D0CEC9", -3),
			amount: 700,
		},
		{
			brick: hexStringToBrickColour("#8C8A88", -4),
			amount: 700,
		},
		{
			brick: hexStringToBrickColour("#646464", -5),
			amount: 700,
		},
		{
			brick: hexStringToBrickColour("#333333", -6),
			amount: 700,
		},
		{
			brick: hexStringToBrickColour("#B2B4B2", -7),
			amount: 700,
		},
	],
};

function createPremadeKitPalettes(
	systemPalette: Palette,
	numberOfKits: number,
) {
	const kitsPalette = premadeKit.bricks.map(({ brick, amount }) => ({
		brick,
		limit: amount * numberOfKits,
	}));
	const nonKitsPalette = systemPalette.filter(
		(b) => !kitsPalette.some((k) => k.brick.rgba === b.brick.rgba),
	);
	return {
		kitsPalette,
		kitPlusExtrasPalette: [...kitsPalette, ...nonKitsPalette],
	};
}

export { premadeKit, createPremadeKitPalettes };
