/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { uniq, uniqWith, isEqual, groupBy, range, pick } from "lodash-es";
import { ensureExhaustive, minByOrThrow } from "@dhau/lang-extras";
import { z } from "zod";
import type { UnixTimestamp, ISODateTimeString } from "./time.ts";
import { isoDateTimeStringSchema } from "./time.ts";
import type {
	Palette,
	PaletteMode,
	PenMarkings,
	PictureConfiguration,
} from "./picture.ts";
import { pictureConfigurationSchema } from "./picture.ts";
import type {
	BrickColour,
	BrickColourHexString,
	TransportBrickColour,
} from "./bricks.ts";
import { rgbHexStringToRgbaNumber } from "../utils/colour-conversions.ts";
import { getClosestPaletteBrick } from "./get-closest-palette-brick.ts";
import type { GenericPosition } from "./geom.ts";
import { multiplySize } from "./geom.ts";
import type { Project } from "./project.ts";
import { hexStringToBrickColour } from "./create-brick-colour.ts";
import {
	compressedToDecimal,
	decimalToCompressed,
	isCompressionCharacter,
} from "./compression.ts";

function isoDateTimeString(timestamp: UnixTimestamp): ISODateTimeString {
	return new Date(Number(timestamp)).toISOString();
}

const transportPenCoordsSchema = z.object({
	type: z.literal("compressed-coords"),
	value: z.string(),
});

type TransportPenCoords = z.infer<typeof transportPenCoordsSchema>;

const transportPensHLinesSchema = z.object({
	type: z.literal("compressed-h-lines"),
	value: z.string(),
});

type TransportPenHLines = z.infer<typeof transportPensHLinesSchema>;

const transportPictureConfigurationSchema = pictureConfigurationSchema
	.pick({
		imageZoom: true,
		imageZoomOffset: true,

		basePlateSize: true,
		numberOfBasePlates: true,

		detailFilter: true,

		brightness: true,
		contrast: true,
		saturation: true,
	})
	.merge(
		z.object({
			buildNumberOfColours: z.number().int().positive(),

			// Only reason this isn't more precise is because don't have the zod
			// to graphql conversion sophisticated enough.
			paletteMode: z.object({
				type: z.enum(["auto", "custom", "hybrid"]),
				// TODO: Required if custom
				palette: z.array(z.string()).optional() as z.Schema<
					readonly string[] | undefined
				>,
				// TODO: Required if auto
				numberOfColours: z.number().int().positive().optional(),
				// TODO: Required if hybrid
				numberOfExtraColours: z.number().int().nonnegative().optional(),
			}),

			// Old projects will have null or undefined values
			fixFaceColours: z.boolean().optional(),
			enhanceFaces: z.boolean().optional(),
			removeBackground: z
				.object({
					// TODO: Must have one of these
					newColour: z.string().optional(),
					// Can't use literal true - graphql can't support
					noBrick: z.boolean().optional(),
				})
				.optional(),

			pen: transportPenCoordsSchema.or(transportPensHLinesSchema).optional(),

			updatedAt: z.string(),
		}),
	);

type TransportPictureConfiguration = z.infer<
	typeof transportPictureConfigurationSchema
>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const transportProjectSchema = z.object({
	currentPicture: transportPictureConfigurationSchema,
	otherVersions: z.array(transportPictureConfigurationSchema) as z.Schema<
		readonly z.infer<typeof transportPictureConfigurationSchema>[]
	>,
	updatedAt: isoDateTimeStringSchema,
});

type TransportProject = z.infer<typeof transportProjectSchema>;

type MissingCustomPaletteColour = {
	readonly type: "missingCustomPaletteColour";
	readonly colour: BrickColourHexString;
};

type MissingPenColour = {
	readonly type: "missingPenColour";
	readonly colour: BrickColourHexString;
};

type ParseError = MissingCustomPaletteColour | MissingPenColour;

function snapTransportPaletteColours(
	systemPalette: Palette,
	sourcePalette: readonly string[],
) {
	// Might be duplicates for closest, so make sure take only unique
	// TODO: Test
	const closestSnappedPalette = uniq(
		sourcePalette.map((c) =>
			getClosestPaletteBrick(systemPalette, rgbHexStringToRgbaNumber(c)),
		),
	);
	const warnings: ReadonlySet<MissingCustomPaletteColour> = new Set(
		sourcePalette
			.filter((c) => !closestSnappedPalette.some((p) => p.hexString === c))
			.map((colour) => ({
				type: "missingCustomPaletteColour",
				colour,
			})),
	);
	return {
		palette: closestSnappedPalette,
		warnings,
	};
}

type TransportParseResult<T> = {
	readonly result: T;
	readonly warnings: ReadonlySet<ParseError>;
};

// TODO: Warnings really need testing
function transportPaletteModeToCore(
	paletteMode: TransportPictureConfiguration["paletteMode"],
	systemPalette: Palette,
): TransportParseResult<PaletteMode> {
	switch (paletteMode.type) {
		case "auto": {
			return {
				result: {
					type: "auto",
					// Transport typing not sophisticated enough for unions
					numberOfColours: paletteMode.numberOfColours!,
				},
				warnings: new Set(),
			};
		}
		case "custom": {
			const { warnings, palette } = snapTransportPaletteColours(
				systemPalette,
				paletteMode.palette!,
			);
			return {
				result: { type: "custom", palette },
				warnings,
			};
		}
		case "hybrid": {
			const { warnings, palette } = snapTransportPaletteColours(
				systemPalette,
				paletteMode.palette!,
			);
			return {
				result: {
					type: "hybrid",
					palette,
					// Transport typing not sophisticated enough for unions
					numberOfExtraColours: paletteMode.numberOfExtraColours!,
				},
				warnings,
			};
		}
		default:
			ensureExhaustive(paletteMode.type);
	}
}

const transportPenColourColumnSeparator = "|";
const transportPenColourCellsSeparator = ",";

if (isCompressionCharacter(transportPenColourColumnSeparator)) {
	throw new Error(
		`${transportPenColourColumnSeparator} col is a compression character, will cause bugs`,
	);
}
if (isCompressionCharacter(transportPenColourCellsSeparator)) {
	throw new Error(
		`${transportPenColourCellsSeparator} cell is a compression character, will cause bugs`,
	);
}

function getPenWarnings(
	closestMap: Record<string, BrickColour>,
): ReadonlySet<MissingPenColour> {
	return new Set(
		Object.entries(closestMap)
			.filter(
				([transportColour, currentBrick]) =>
					transportColour !== currentBrick.rgba.toString(),
			)
			.map(([transportColour]) => ({
				type: "missingPenColour",
				colour: transportColour,
			})),
	);
}

function transportPenHLinesToCore(
	{ value: encodedPen }: TransportPenHLines,
	currentPalette: Palette,
): TransportParseResult<PenMarkings> {
	if (encodedPen === "") {
		return { result: [], warnings: new Set() };
	}

	const pen = encodedPen
		.split(transportPenColourColumnSeparator)
		.flatMap((line) => {
			const [colourEncoded, yEncoded, ...xLengthPairsEncoded] = line.split(
				transportPenColourCellsSeparator,
			);
			if (xLengthPairsEncoded.length % 2 !== 0) {
				throw new Error("Invalid format");
			}
			// const xs = range()ysEncoded.map((y) => compressedToDecimal(y));
			const xs = range(0, xLengthPairsEncoded.length, 2).flatMap((i) => {
				const startX = compressedToDecimal(xLengthPairsEncoded[i]);
				const lineLength = compressedToDecimal(xLengthPairsEncoded[i + 1]);
				return range(startX, startX + lineLength);
			});
			const y = compressedToDecimal(yEncoded);
			const colourRgba = compressedToDecimal(colourEncoded);
			return xs.map((x) => ({
				colourRgba,
				position: { x, y },
			}));
		});
	const penColours = uniq(pen.map((p) => p.colourRgba));
	const closestMap: Record<string, BrickColour> = Object.fromEntries(
		penColours.map((c) => [
			c.toString(),
			getClosestPaletteBrick(currentPalette, c),
		]),
	);
	const result = pen.map((p) => ({
		position: p.position,
		colour: closestMap[p.colourRgba.toString()],
	}));
	return { result, warnings: getPenWarnings(closestMap) };
}

function transportPenCoordsToCore(
	{ value: encodedPen }: TransportPenCoords,
	currentPalette: Palette,
): TransportParseResult<PenMarkings> {
	if (encodedPen === "") {
		return { result: [], warnings: new Set() };
	}

	const pen = encodedPen
		.split(transportPenColourColumnSeparator)
		.flatMap((line) => {
			const [colourEncoded, xEncoded, ...ysEncoded] = line.split(
				transportPenColourCellsSeparator,
			);
			const ys = ysEncoded.map((y) => compressedToDecimal(y));
			const x = compressedToDecimal(xEncoded);
			const colourRgba = compressedToDecimal(colourEncoded);
			return ys.map((y) => ({
				colourRgba,
				position: { x, y },
			}));
		});
	const penColours = uniq(pen.map((p) => p.colourRgba));
	const closestMap: Record<string, BrickColour> = Object.fromEntries(
		penColours.map((c) => [
			c.toString(),
			getClosestPaletteBrick(currentPalette, c),
		]),
	);
	const result = pen.map((p) => ({
		position: p.position,
		colour: closestMap[p.colourRgba.toString()],
	}));
	return { result, warnings: getPenWarnings(closestMap) };
}

// TODO: Warnings really need testing
function transportPenToCore(
	transportPen: TransportPictureConfiguration["pen"],
	currentPalette: Palette,
): TransportParseResult<PenMarkings> {
	if (!transportPen) {
		return { result: [], warnings: new Set() };
	}

	switch (transportPen.type) {
		case "compressed-coords":
			return transportPenCoordsToCore(transportPen, currentPalette);
		case "compressed-h-lines":
			return transportPenHLinesToCore(transportPen, currentPalette);
		default:
			throw new Error("Invalid pen type");
	}
}

/** @deprecated filter this out */
function transportLegacyPenToCore(
	pen: readonly {
		readonly position: GenericPosition<number>;
		readonly colour: string;
	}[],
	currentPalette: Palette,
): TransportParseResult<PenMarkings> {
	const penColours = uniq(pen.map((p) => p.colour));
	const closestMap: Record<string, BrickColour> = Object.fromEntries(
		penColours.map((c) => [
			c,
			getClosestPaletteBrick(currentPalette, rgbHexStringToRgbaNumber(c)),
		]),
	);
	const result = pen.map((p) => ({
		position: p.position,
		colour: closestMap[p.colour],
	}));
	const warnings: ReadonlySet<MissingPenColour> = new Set(
		Object.entries(closestMap)
			.filter(
				([transportColour, currentBrick]) =>
					transportColour !== currentBrick.hexString,
			)
			.map(([transportColour]) => ({
				type: "missingPenColour",
				colour: transportColour,
			})),
	);
	return { result, warnings };
}

function isoStringToDate(s: string) {
	const b = s.split(/\D+/).map((p) => Number(p));
	return new Date(Date.UTC(b[0], b[1] - 1, b[2], b[3], b[4], b[5], b[6]));
}

function transportPictureConfigurationToCore(
	config: Omit<TransportPictureConfiguration, "buildNumberOfColours">,
	currentPalette: Palette,
): TransportParseResult<PictureConfiguration> {
	const paletteModeResult = transportPaletteModeToCore(
		config.paletteMode,
		currentPalette,
	);
	const penResult = transportPenToCore(config.pen, currentPalette);
	return {
		warnings: new Set([
			...Array.from(paletteModeResult.warnings),
			...Array.from(penResult.warnings),
		]),
		result: {
			...pick(
				config,
				"imageZoom",
				"imageZoomOffset",

				"basePlateSize",
				"numberOfBasePlates",

				"brightness",
				"contrast",
				"saturation",

				"detailFilter",
			),
			enhanceFaces: !!config.enhanceFaces,
			fixFaceColours: !!config.fixFaceColours,
			removeBackground: (() => {
				if (!config.removeBackground) {
					return undefined;
				}

				if (
					"newColour" in config.removeBackground &&
					config.removeBackground.newColour
				) {
					return {
						newColour: getClosestPaletteBrick(
							currentPalette,
							rgbHexStringToRgbaNumber(config.removeBackground.newColour),
						),
					};
				}
				if (
					"noBrick" in config.removeBackground &&
					config.removeBackground.noBrick
				) {
					return {
						noBrick: true,
					};
				}
				return undefined;
			})(),

			numberOfBricks: multiplySize(
				config.numberOfBasePlates,
				config.basePlateSize,
			),

			paletteMode: paletteModeResult.result,

			pen: penResult.result,

			updatedAt: isoStringToDate(config.updatedAt.toString()).getTime(),
		},
	};
}

function transportProjectToCore(
	project: TransportProject,
	currentPalette: Palette,
): TransportParseResult<Project> {
	const currentPictureResult = transportPictureConfigurationToCore(
		project.currentPicture,
		currentPalette,
	);
	const otherVersionResults = project.otherVersions.map((o) =>
		transportPictureConfigurationToCore(o, currentPalette),
	);
	const warnings = uniqWith(
		[
			...Array.from(currentPictureResult.warnings),
			...otherVersionResults.flatMap((o) => Array.from(o.warnings)),
		],
		isEqual,
	);
	return {
		warnings: new Set(warnings),
		result: {
			currentPicture: currentPictureResult.result,
			otherVersions: otherVersionResults.map((o) => o.result),
			updatedAt: isoStringToDate(project.updatedAt.toString()).getTime(),
		},
	};
}

type TransportPalette = readonly TransportBrickColour[];

function transportPaletteToCore(palette: TransportPalette): Palette {
	return palette.map((c) => ({
		brick: hexStringToBrickColour(c.colour, c.identifier),
		limit: undefined,
	}));
}

function paletteModeToTransportPaletteMode(
	paletteMode: PaletteMode,
): TransportPictureConfiguration["paletteMode"] {
	switch (paletteMode.type) {
		case "auto":
			return {
				type: "auto",
				numberOfColours: paletteMode.numberOfColours,
			};
		case "custom":
			return {
				type: "custom",
				palette: paletteMode.palette.map((b) => b.hexString),
			};
		case "hybrid":
			return {
				type: "hybrid",
				palette: paletteMode.palette.map((b) => b.hexString),
				numberOfExtraColours: paletteMode.numberOfExtraColours,
			};
		default:
			throw new Error("Invalid palette mode");
	}
}

function corePensToTransportPensCoords(pen: PenMarkings) {
	const colourXGrouped = groupBy(pen, (p) =>
		[p.colour.rgba, p.position.x]
			.map(decimalToCompressed)
			.join(transportPenColourCellsSeparator),
	);
	return {
		type: "compressed-coords" as const,
		value: Object.entries(colourXGrouped)
			.map(([colourX, colPens]) =>
				[
					colourX,
					...colPens.map((p) => decimalToCompressed(p.position.y)),
				].join(transportPenColourCellsSeparator),
			)
			.join(transportPenColourColumnSeparator),
	};
}

function corePensToTransportPensHLines(pen: PenMarkings) {
	const colourYGrouped = groupBy(pen, (p) =>
		[p.colour.rgba, p.position.y]
			.map(decimalToCompressed)
			.join(transportPenColourCellsSeparator),
	);
	return {
		type: "compressed-h-lines" as const,
		value: Object.entries(colourYGrouped)
			.map(([colourY, rowPens]) => {
				const [initialX, ...remainingXs] = rowPens
					.map((p) => p.position.x)
					.sort();
				let startX = initialX;
				let lineLength = 1;
				const lines: {
					readonly startX: number;
					readonly lineLength: number;
				}[] = [];
				remainingXs.forEach((x) => {
					if (x === startX + lineLength) {
						lineLength += 1;
					} else {
						lines.push({
							startX,
							lineLength,
						});
						lineLength = 1;
						startX = x;
					}
				});
				lines.push({
					startX,
					lineLength,
				});

				return [
					colourY,
					...lines.map(
						(line) =>
							decimalToCompressed(line.startX) +
							transportPenColourCellsSeparator +
							decimalToCompressed(line.lineLength),
					),
				].join(transportPenColourCellsSeparator);
			})
			.join(transportPenColourColumnSeparator),
	};
}

function corePensToTransportPens(pen: PenMarkings) {
	if (pen.length === 0) {
		return undefined;
	}

	const possible = [
		corePensToTransportPensHLines(pen),
		corePensToTransportPensCoords(pen),
	];
	return minByOrThrow(possible, (p) => p.value.length);
}

function corePictureToTransportPicture(
	picture: PictureConfiguration,
	buildNumberOfColours: number,
): TransportPictureConfiguration {
	return {
		...pick(
			picture,
			"imageZoom",
			"imageZoomOffset",

			"basePlateSize",
			"numberOfBasePlates",

			"brightness",
			"contrast",
			"saturation",

			"detailFilter",

			"fixFaceColours",
			"enhanceFaces",
		),
		buildNumberOfColours,

		removeBackground: (() => {
			if (!picture.removeBackground) {
				return undefined;
			}
			if ("noBrick" in picture.removeBackground) {
				return {
					noBrick: true,
				};
			}
			return {
				newColour: picture.removeBackground.newColour.hexString,
			};
		})(),

		paletteMode: paletteModeToTransportPaletteMode(picture.paletteMode),

		pen: corePensToTransportPens(picture.pen),

		updatedAt: isoDateTimeString(picture.updatedAt),
	};
}

function projectToTransportProject(
	project: Project,
	currentPictureBuildNumberOfColours: number,
): Omit<TransportProject, "sourceImageUrl" | "id"> {
	return {
		currentPicture: corePictureToTransportPicture(
			project.currentPicture,
			currentPictureBuildNumberOfColours,
		),
		// Not used anywhere and won't have build, so pass 0
		otherVersions: project.otherVersions.map((p) =>
			corePictureToTransportPicture(p, 0),
		),
		updatedAt: isoDateTimeString(project.updatedAt),
	};
}

export type {
	TransportPalette,
	TransportProject,
	TransportPictureConfiguration,
};
export {
	corePensToTransportPens,
	transportPenToCore,
	transportLegacyPenToCore,
	transportPictureConfigurationToCore,
	transportPaletteToCore,
	corePictureToTransportPicture,
	transportProjectToCore,
	transportPaletteModeToCore,
	projectToTransportProject,
	isoDateTimeString,
	transportPictureConfigurationSchema,
	// Only for testing util
	corePensToTransportPensCoords,
};
