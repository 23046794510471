/* eslint-disable no-bitwise */
import { ensureExhaustive } from "@dhau/lang-extras";
import {
	uint8ArrayAlloc,
	readUInt32BE,
	writeUInt32BE,
} from "../utils/uint8-array.ts";
import type { Bitmap } from "./types.ts";
import { bitmapChannels } from "./types.ts";

function rotateBitmapAtRightAngles(bitmap: Bitmap, deg: 0 | 90 | 180 | 270) {
	if (Math.abs(deg) === 0) {
		return bitmap;
	}

	const { width, height } = bitmap;

	let angle;
	switch (deg) {
		case 0:
			angle = 0;
			break;
		case 90:
			angle = 90;
			break;
		case 180:
			angle = 180;
			break;
		case 270:
			angle = -90;
			break;
		default:
			ensureExhaustive(deg);
	}
	// After this switch block, angle will be 90, 180 or -90

	const newWidth = angle === 180 ? width : height;
	const newHeight = angle === 180 ? height : width;

	const dstArray = uint8ArrayAlloc(width * height * bitmapChannels);

	// function to translate the x, y coordinate to the index of the pixel in the buffer
	function createIdxTranslationFunction(w: number) {
		return (x: number, y: number) => (y * w + x) << 2;
	}

	const srcIdxFunction = createIdxTranslationFunction(width);
	const dstIdxFunction = createIdxTranslationFunction(newWidth);

	for (let x = 0; x < width; x++) {
		for (let y = 0; y < height; y++) {
			const srcIdx = srcIdxFunction(x, y);
			const pixelRGBA = readUInt32BE(bitmap.data, srcIdx);

			let dstIdx;
			switch (angle) {
				case 90:
					dstIdx = dstIdxFunction(y, width - x - 1);
					break;
				case -90:
					dstIdx = dstIdxFunction(height - y - 1, x);
					break;
				case 180:
					dstIdx = dstIdxFunction(width - x - 1, height - y - 1);
					break;
				default:
					throw new Error("Unsupported matrix rotation angle");
			}

			writeUInt32BE(dstArray, pixelRGBA, dstIdx);
		}
	}

	return {
		data: dstArray,
		width: newWidth,
		height: newHeight,
	};
}

export { rotateBitmapAtRightAngles };
