import { compact, range, shuffle } from "lodash-es";
import type { CognitoUserSession } from "amazon-cognito-identity-js";
import {
	CognitoUserPool,
	CognitoUser,
	AuthenticationDetails,
	CookieStorage,
	CognitoRefreshToken,
	CognitoUserAttribute,
} from "amazon-cognito-identity-js";
import type { GraphQLClient } from "graphql-request";
import { gql } from "graphql-request";
import { ClientError } from "~/utils/client-error.ts";
import {
	authenticateUser,
	signUp,
	getUserAttributes,
	getSession,
	isAwsError,
	forgotPassword,
	confirmPassword,
	changePassword,
	updateUserAttributes,
} from "./cognito-promise.ts";

type UnixTimestamp = number;
type ISOStringDate = string;

type AuthProfile = {
	readonly firstName: string;
	readonly middleName: string | undefined;
	readonly lastName: string | undefined;
	readonly country: string;
	readonly howHeard: string;
	readonly currentSite: string;
	readonly originSite: string;
	readonly ipAddress: string | undefined;
	readonly ipAddressCountry: string | undefined;
	readonly birthdate: ISOStringDate | undefined;
	readonly artProductCreator: boolean | undefined;
};

type AccessToken = {
	readonly token: string;
	readonly expiry: UnixTimestamp;
};

// These will be empty if went through the setup account with email only
// process in creator app
type AuthUser = Partial<AuthProfile> & {
	readonly id: string;
	readonly emailAddress: string;
	readonly jwtRefreshToken: {
		readonly token: string;
	};
	readonly jwtAccessToken: AccessToken;
};

type CompleteAuthUser = AuthUser & AuthProfile;

type UserPair<TUser extends AuthUser = AuthUser> = {
	readonly authUser: TUser;
	readonly cognitoUser: CognitoUser;
};

type Config = {
	readonly userPoolId: string;
	readonly appClientId: string;
	readonly cookieDomain: string;
	readonly originSite: string;
	readonly apiClient: GraphQLClient;
};

const isEmailAddressUsedQuery = gql`
	query isEmailAddressUsed($emailAddress: String!) {
		isEmailAddressUsed(emailAddress: $emailAddress)
	}
`;

type IsEmailAddressUsedQuery = {
	readonly isEmailAddressUsed: boolean;
};

type IsEmailAddressUsedVariables = {
	readonly emailAddress: string;
};

// const nameMappings: { readonly [key in keyof AuthProfile]: string } = {
// Mapping typing of object.entries didnt work

const nameMappings: readonly Readonly<
	[keyof AuthProfile, string, (value: string) => any, boolean]
>[] = [
	["firstName", "given_name", String, true],
	["lastName", "family_name", String, true],
	["middleName", "middle_name", String, true],
	["country", "custom:country", String, true],
	["howHeard", "custom:how_heard", String, true],
	["artProductCreator", "custom:art_product_creator", Boolean, false],
	["birthdate", "birthdate", String, true],
	["originSite", "custom:origin_site", String, true],
	["currentSite", "custom:current_site", String, true],
	["ipAddress", "custom:ip_address", String, false],
	["ipAddressCountry", "custom:ip_address_country", String, false],
];

const passwordLength = 12;
const passwordClasses = [
	"0123456789".split(""),
	"!@#$%^&*()_+~|}{[]\\:;?></=".split(""),
	"abcdefghijklmnopqrstuvwxyz".split(""),
	"ABCDEFGHIJKLMNOPQRSTUVWXYZ".split(""),
];

function generatePassword() {
	if (passwordLength < passwordClasses.length) {
		throw new Error("Password length must be at least 4");
	}

	const oneFromEachClass = passwordClasses.map(
		(c) => c[Math.floor(Math.random() * c.length)],
	);
	const rest = range(0, passwordLength - passwordClasses.length).map(() => {
		const randomClass =
			passwordClasses[Math.floor(Math.random() * passwordClasses.length)];
		return randomClass[Math.floor(Math.random() * randomClass.length)];
	});
	const allChars = shuffle([...oneFromEachClass, ...rest]);
	return allChars.join("");
}

type Writeable<T extends Record<string, any>, K extends string> = {
	[P in K]: T[P];
};

function cognitoAttributesToProfile(
	attributes: readonly CognitoUserAttribute[],
): Partial<AuthProfile> {
	const profile: Partial<Writeable<AuthProfile, keyof AuthProfile>> = {};
	nameMappings.forEach(([profileField, attrName, constructor]) => {
		const attr = attributes.find((a) => a.getName() === attrName);
		if (!attr) {
			return;
		}

		profile[profileField] = constructor(attr.getValue());
	});
	return profile;
}

type SignUpWithoutPasswordDetails = {
	readonly firstName: string;
	readonly middleName: string | undefined;
	readonly lastName: string | undefined;
	readonly ipAddress:
		| {
				readonly value: string;
				readonly country: string | undefined;
		  }
		| undefined;
};

function createAuthService({
	userPoolId,
	appClientId,
	cookieDomain,
	apiClient,
	originSite,
}: Config) {
	// Should be secure, but makes local testing a pain. TODO: Pass it in as an arg
	const storage = new CookieStorage({
		secure: false,
		domain: cookieDomain,
	});
	const userPool = new CognitoUserPool({
		UserPoolId: userPoolId,
		ClientId: appClientId,
		Storage: storage,
	});

	async function signInPromise(
		emailAddress: string,
		password: string,
	): Promise<{ authUser: AuthUser; cognitoUser: CognitoUser }> {
		const cognitoUser = new CognitoUser({
			Username: emailAddress,
			Pool: userPool,
			Storage: storage,
		});
		const session = await authenticateUser(
			cognitoUser,
			new AuthenticationDetails({
				Username: emailAddress,
				Password: password,
			}),
		);
		const accessToken = session.getAccessToken();
		const attrs = await getUserAttributes(cognitoUser);
		return {
			authUser: {
				id: cognitoUser.getUsername(),
				emailAddress,
				jwtRefreshToken: {
					token: session.getRefreshToken().getToken(),
				},
				jwtAccessToken: {
					token: accessToken.getJwtToken(),
					expiry: accessToken.getExpiration() * 1000,
				},
				...cognitoAttributesToProfile(attrs),
			},
			cognitoUser,
		};
	}

	return {
		async isEmailAddressUsed(emailAddress: string): Promise<boolean> {
			const result = await apiClient.request<
				IsEmailAddressUsedQuery,
				IsEmailAddressUsedVariables
			>(isEmailAddressUsedQuery, { emailAddress });
			return result.isEmailAddressUsed;
		},
		async signUpWithoutPassword(
			emailAddress: string,
			{
				firstName,
				middleName,
				lastName,
				ipAddress,
			}: SignUpWithoutPasswordDetails,
		) {
			const temporaryPassword = generatePassword();

			const signedUp = await signUp(
				userPool,
				emailAddress,
				temporaryPassword,
				compact([
					new CognitoUserAttribute({ Name: "given_name", Value: firstName }),
					new CognitoUserAttribute({
						Name: "custom:origin_site",
						Value: originSite,
					}),
					new CognitoUserAttribute({
						Name: "custom:current_site",
						Value: originSite,
					}),
					ipAddress
						? new CognitoUserAttribute({
								Name: "custom:ip_address",
								Value: ipAddress.value,
							})
						: undefined,
					ipAddress?.country
						? new CognitoUserAttribute({
								Name: "custom:ip_address_country",
								Value: ipAddress.country,
							})
						: undefined,
					middleName
						? new CognitoUserAttribute({
								Name: "middle_name",
								Value: middleName,
							})
						: undefined,
					lastName
						? new CognitoUserAttribute({ Name: "family_name", Value: lastName })
						: undefined,
				]),
				{ source: "emailOnlySignUp" },
			);
			if (!signedUp) {
				throw new ClientError("Email address already in use");
			}

			const { authUser } = await signInPromise(emailAddress, temporaryPassword);
			return authUser;
		},
		refreshAccessToken: async (refreshToken: string): Promise<AccessToken> => {
			const user = userPool.getCurrentUser();
			if (!user) {
				throw new Error("Current user required");
			}
			return new Promise<AccessToken>((resolve, reject) => {
				user.refreshSession(
					new CognitoRefreshToken({ RefreshToken: refreshToken }),
					(error?: Error, session?: CognitoUserSession) => {
						if (error) {
							reject(error);
							return;
						}
						if (!session) {
							reject(new Error("No session"));
							return;
						}

						const accessToken = session.getAccessToken();
						resolve({
							token: accessToken.getJwtToken(),
							expiry: accessToken.getExpiration() * 1000,
						});
					},
				);
			});
		},
		loadCurrentUser: async (): Promise<UserPair | undefined> => {
			const cognitoUser = userPool.getCurrentUser();
			if (!cognitoUser) {
				return undefined;
			}
			// refresh the session if the session expired.
			const session = await getSession(cognitoUser);
			let userAttributes;
			try {
				userAttributes = await getUserAttributes(cognitoUser);
			} catch (e) {
				// User has been deleted. Probably shouldn't happen
				if (isAwsError(e) && (e as any).code === "UserNotFoundException") {
					cognitoUser.signOut();
					return undefined;
				}
				throw e;
			}

			const attrs = Object.fromEntries(
				userAttributes.map((attr) => [attr.getName(), attr.getValue()]),
			);
			if (attrs["custom:current_site"] !== originSite) {
				await updateUserAttributes(cognitoUser, [
					new CognitoUserAttribute({
						Name: "custom:current_site",
						Value: originSite,
					}),
				]);
				attrs["custom:current_site"] = originSite;
			}

			const accessToken = session.getAccessToken();
			return {
				authUser: {
					id: cognitoUser.getUsername(),
					emailAddress: attrs.email,
					jwtRefreshToken: {
						token: session.getRefreshToken().getToken(),
					},
					jwtAccessToken: {
						token: accessToken.getJwtToken(),
						expiry: accessToken.getExpiration() * 1000,
					},
					...cognitoAttributesToProfile(userAttributes),
				},
				cognitoUser,
			};
		},
		signOutCurrentUser() {
			const current = userPool.getCurrentUser();
			if (!current) {
				return;
			}
			current.signOut();
		},
		signIn: async (emailAddress: string, password: string) =>
			signInPromise(emailAddress, password),
		forgotPassword: async (emailAddress: string) => {
			const user = new CognitoUser({
				Username: emailAddress,
				Pool: userPool,
				Storage: storage,
			});
			await forgotPassword(user);
		},
		confirmPassword: async (
			emailAddress: string,
			verificationCode: string,
			password: string,
		) => {
			const user = new CognitoUser({
				Username: emailAddress,
				Pool: userPool,
				Storage: storage,
			});
			await confirmPassword(user, verificationCode, password);
		},
		changePassword: async (
			cognitoUser: CognitoUser,
			oldPassword: string,
			newPassword: string,
		) => {
			await changePassword(cognitoUser, oldPassword, newPassword);
		},
	};
}

type AuthService = ReturnType<typeof createAuthService>;

export { createAuthService };
export type { AccessToken, AuthProfile, AuthUser, CompleteAuthUser };
export type { AuthService, UserPair };
