import type { Store } from "@reduxjs/toolkit";
import { debounce } from "lodash-es";
import type {
	Bitmap,
	BuildResult,
	ImageBuildCache,
	UnixTimestamp,
} from "@brickme/project-core/src";
import { createBrickedImageFromSourceWithCache } from "@brickme/project-core/src";
import type { OpenCv } from "@brickme/opencv";
import type { AppState } from "~/store/root-reducer.ts";
import type { RunProcess, ProcessRun } from "~/utils/create-process-runner.ts";
import { isProcessAbortError } from "~/utils/create-process-runner.ts";
import { isOnceOffLoaded } from "~/utils/loading.ts";
import { setBuild } from "./store-slice.ts";
import creatorProjectToBuildSources from "./creator-project-to-build-sources.ts";

type PendingBuild = {
	readonly timestamp: UnixTimestamp;
	readonly run: ProcessRun<BuildResult>;
};

// TODO: Consider the case of a pen drawing. If you're doing a line then might be
// aborting each brick, meaning won't see anything for a long time
function createBuildListener(
	store: Store<AppState>,
	openCvLoader: Promise<OpenCv>,
	runHeavyProcess: RunProcess,
): () => void {
	// Don't put into redux, better to have up to date non-debounced access
	let building: PendingBuild[] = [];
	let cacheSourceImage: Bitmap | undefined;
	let buildCache: ImageBuildCache | undefined;
	let openCv: OpenCv | undefined;

	const listener = (): void => {
		const {
			workspace: { openProject },
			reference: { systemPalette: systemPaletteLoad },
		} = store.getState();

		// No open project
		if (!openProject) {
			// TODO: Cancel current build
			building = [];
			buildCache = undefined;
			return;
		}

		// We've switched projects
		// This used to be a a lot more complex. However was a problem if dimensions of
		// sources were the same - would keep old source. I can't see why we're doing
		// this fine grained check - source only changes on new project.
		// openProject.project.sourceImage.bitmap !== cacheSourceImage &&
		// // Compare values by strict equality, but want to allow bitmap object
		// // itself to change if needed
		// openProject.project.sourceImage.bitmap.width !==
		// 	cacheSourceImage?.width &&
		// openProject.project.sourceImage.bitmap.height !==
		// 	cacheSourceImage?.height &&
		// openProject.project.sourceImage.bitmap.data !== cacheSourceImage?.data
		if (openProject.project.sourceImage.bitmap !== cacheSourceImage) {
			buildCache = undefined;
			cacheSourceImage = openProject.project.sourceImage.bitmap;
		}

		// Build is up to date with config
		if (
			openProject.project.currentPicture.updatedAt ===
			openProject.builtCurrentPictureUpdatedAt
		) {
			building = [];
			return;
		}

		// Building current version already (>= doesn't make sense unless there's a bug
		// somewhere or listeners get called out of order)
		if (
			building.some(
				(p) => p.timestamp >= openProject.project.currentPicture.updatedAt,
			)
		) {
			return;
		}

		// Wait for system palette to load
		if (!isOnceOffLoaded(systemPaletteLoad)) {
			return;
		}

		// Only allow 1 build at a time. We assume that setting a build when finished will start on next build
		if (building.length > 0) {
			return;
		}

		// open cv not loaded yet
		if (!openCv) {
			return;
		}

		if (import.meta.env.NODE_ENV === "development") {
			console.time("Build");
		}

		const generator = createBrickedImageFromSourceWithCache(
			{ openCv },
			creatorProjectToBuildSources(openProject.project),
			systemPaletteLoad.data,
			openProject.project.currentPicture,
			buildCache,
		);
		const run = runHeavyProcess({
			generator,
			priority: 1,
		});
		const thisTimestamp = openProject.project.currentPicture.updatedAt;
		building.push({ run, timestamp: thisTimestamp });

		(async () => {
			try {
				const buildResult = await run.promise;
				if (import.meta.env.NODE_ENV === "development") {
					console.timeEnd("Build");
				}
				buildCache = buildResult.updatedCache;
				building = [];
				store.dispatch(
					setBuild({ output: buildResult.output, timestamp: thisTimestamp }),
				);
			} catch (e) {
				if (isProcessAbortError(e)) {
					// Expected - aborted
					return;
				}
				throw e;
			}
		})();
	};

	(async () => {
		openCv = await openCvLoader;
		// Fire listener in case missed an op that was waiting on openCv
		listener();
	})();

	return debounce(listener, 1, { maxWait: 1 });
}

export default createBuildListener;
