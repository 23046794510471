import "./polyfill-resize-observer.ts";
// Promise.finally not present in some browsers (e.g. safari ios by the looks)
import "core-js/features/promise";
import "@formatjs/intl-numberformat/polyfill";
import "@formatjs/intl-numberformat/locale-data/en";
import type { ReactNode } from "react";
import React, { StrictMode } from "react";
import { render } from "react-dom";
import "nouislider/distribute/nouislider.css";
import "~/styles/sass/main.sass";
import ReactModal from "react-modal";
import { init as sentryInit, browserTracingIntegration } from "@sentry/react";
import type { EventHint, ErrorEvent } from "@sentry/react";
import { isMeasurementUnits } from "@brickme/project-core/src/model/formatted-project-size.ts";
import { maxNumberOfBaseplates } from "@brickme/project-core/src";
import { PasswordProtected } from "~/frontend-common/password-protected.tsx";
import AppContainer from "~/app/app-container.tsx";
import {
	optionalRuntimeEnv,
	optionalRuntimeEnvInt,
	requiredRuntimeEnv,
} from "~/utils/ensure-env.ts";
import { ClientError } from "./utils/client-error.ts";
import packageInfo from "../package.json";

ReactModal.setAppElement("#root");

const sentryDsn = optionalRuntimeEnv("SENTRY_DSN");
if (sentryDsn) {
	const sentryEnvironment = optionalRuntimeEnv("SENTRY_ENVIRONMENT");
	const sentryRelease = optionalRuntimeEnv("SENTRY_RELEASE");
	sentryInit({
		dsn: sentryDsn,
		environment: sentryEnvironment,
		release: sentryRelease,
		integrations: [browserTracingIntegration()],
		normalizeDepth: 6,
		// TODO: Check on performance impacts?
		// Samples for every session in general. Don't want samples for non-error
		// replaysSessionSampleRate: 0,
		// // We want every error session sampled
		// replaysOnErrorSampleRate: 1.0,
		beforeSend(event: ErrorEvent, hint: EventHint) {
			if (
				hint.originalException &&
				hint.originalException instanceof ClientError
			) {
				return null;
			}
			return event;
		},
	});
}

const protectedPassword = optionalRuntimeEnv("PROTECTED_PASSWORD");

type PossiblePasswordProtectedProps = {
	readonly children: ReactNode;
};

function PossiblePasswordProtected({
	children,
}: PossiblePasswordProtectedProps) {
	if (protectedPassword) {
		return (
			<PasswordProtected password={protectedPassword}>
				{children}
			</PasswordProtected>
		);
	}
	return <>{children}</>;
}

// eslint-disable-next-line no-console
console.log("App Version", packageInfo.version);

// Fix vh for mobile browsers. See https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
function updateViewHeight() {
	const vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty("--vh", `${vh}px`);
}
updateViewHeight();
window.addEventListener("resize", updateViewHeight);

// Measurement units
const rawMeasurementUnits = requiredRuntimeEnv("DEFAULT_MEASUREMENT_UNITS");
const defaultMeasurementUnits = isMeasurementUnits(rawMeasurementUnits)
	? rawMeasurementUnits
	: "metric";

render(
	<StrictMode>
		<PossiblePasswordProtected>
			<AppContainer
				version={packageInfo.version}
				url={window.location.href}
				userAgent={navigator.userAgent || navigator.vendor || "unknown"}
				gtmId={optionalRuntimeEnv("GTM_ID")}
				siteCode={requiredRuntimeEnv("SITE_CODE")}
				gorgiasId={requiredRuntimeEnv("GORGIAS_ID")}
				mediaBaseUrl={requiredRuntimeEnv("MEDIA_BASE_URL")}
				defaultCountryCode={requiredRuntimeEnv("DEFAULT_COUNTRY_CODE")}
				ipInfoToken={requiredRuntimeEnv("IP_INFO_TOKEN")}
				platformCookieDomain={requiredRuntimeEnv("PLATFORM_COOKIE_DOMAIN")}
				defaultMeasurementUnits={defaultMeasurementUnits}
				cognitoPoolConfig={{
					id: requiredRuntimeEnv("USER_POOL_ID"),
					appClientId: requiredRuntimeEnv("USER_POOL_APP_CLIENT_ID"),
				}}
				shopifyConfig={{
					shopDomain: requiredRuntimeEnv("SHOPIFY_SHOP_DOMAIN"),
					storefrontAccessToken: requiredRuntimeEnv("STOREFRONT_ACCESS_TOKEN"),
				}}
				apiUrl={requiredRuntimeEnv("API_URL")}
				websiteUrl={requiredRuntimeEnv("WEBSITE_URL")}
				basePlateSizes={requiredRuntimeEnv("BASEPLATE_SIZES")
					.split(",")
					.map((s) => parseInt(s, 10))}
				maxNumberOfSelectableBasePlates={
					optionalRuntimeEnvInt("NUM_SELECTABLE_BASEPLATES") ??
					maxNumberOfBaseplates
				}
			/>
		</PossiblePasswordProtected>
	</StrictMode>,
	document.getElementById("root"),
);
