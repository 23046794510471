/* eslint-disable no-param-reassign */
import type { Draft } from "@reduxjs/toolkit";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { captureMessage as sentryCaptureMessage } from "@sentry/react";
import { v4 as uuid } from "uuid";
import { unknownToString } from "@dhau/lang-extras";
import { backOff } from "exponential-backoff";
import type { UnixTimestamp } from "@brickme/project-core/src";
import {
	projectToTransportProject,
	loadBitmapFromUrl,
	numberOfBrickColours,
	removeTypenames,
	createBitmapMaskFromBitmap,
	getMissingBuildSources,
} from "@brickme/project-core/src";
import { imageMimeTypeToFileExtension } from "@brickme/project-core/src/utils/mime-types.ts";
import type {
	SwitchGuestProjectToUserMutation,
	SwitchGuestProjectToUserVariables,
} from "~/api/switch-guest-project-to-user-mutation.ts";
import switchGuestProjectToUserMutation from "~/api/switch-guest-project-to-user-mutation.ts";
import createProjectMutation from "~/api/create-project-mutation.graphql";
import type {
	CreateProjectMutation,
	CreateProjectMutationVariables,
} from "~/api/create-project-mutation.generated.ts";
import type {
	UpdateProjectMutationVariables,
	UpdateProjectMutation,
} from "~/api/update-project-mutation.generated.ts";
import updateProjectMutation from "~/api/update-project-mutation.graphql";
import type {
	DuplicateProjectMutationVariables,
	DuplicateProjectMutation,
} from "~/api/duplicate-project-mutation.ts";
import duplicateProjectMutation from "~/api/duplicate-project-mutation.ts";
import type { StoreThunkApiConfig } from "~/store-config.ts";
import type { State as UserState } from "~/features/user/store-slice.ts";
import { loadSavedProject, signOut } from "~/features/user/store-slice.ts";
import {
	setBackgroundMaskImage,
	setColorisationImage,
	setEnhanceFacesImage,
	setFaceBoundingBoxes,
} from "~/features/workspace/store-slice.ts";
import type { State as WorkspaceState } from "~/features/workspace/state.ts";
import { creatorProjectToCoreProject } from "~/features/workspace/state.ts";
import waitUntil from "~/utils/wait-until.ts";
import {
	isOnceOffLoaded,
	isOnceOffLoading,
	loadedOnceOffLoad,
	errorOnceOffLoad,
	loadingOnceOffLoad,
	pendingOnceOffLoad,
} from "~/utils/loading.ts";
import type {
	GetSavedDesignSourcesQuery,
	GetSavedDesignSourcesVariables,
} from "~/api/get-saved-design-sources-query.ts";
import getSavedDesignSourcesQuery from "~/api/get-saved-design-sources-query.ts";
import type { FullSavedProject } from "~/api/get-saved-designs-query.ts";

const name = "saveProject";

type SavedUserType = "signedIn" | "guest";

type LoadingApiProject = {
	readonly type: "loadingApiProject";
};

type UploadingSourceImage = {
	readonly type: "uploadingSourceImage";
};

type UploadedSourceImage = {
	readonly type: "uploadedSourceImage";
	readonly tempSourceKey: string;
};

type UploadSourceImageError = {
	readonly type: "uploadSourceImageError";
};

type CreatingApiProject = {
	readonly type: "creatingApiProject";
	readonly tempSourceKey: string;
};

type CreateApiProjectError = {
	readonly type: "createApiProjectError";
	readonly tempSourceKey: string;
};

type SavedToBackend = {
	readonly type: "savedToBackend";
	readonly id: string;
	readonly sourceImageKey: string;
	readonly updatedAt: UnixTimestamp;
	readonly userType: SavedUserType;
};

type UpdatingInBackend = {
	readonly type: "updatingToBackend";
	readonly id: string;
	readonly sourceImageKey: string;
	readonly previousUpdatedAt: UnixTimestamp;
	readonly userType: SavedUserType;
};

type UpdatingError = {
	readonly type: "updatingError";
	readonly id: string;
	readonly sourceImageKey: string;
	readonly updatedAt: UnixTimestamp;
	readonly userType: SavedUserType;
};

type SaveState =
	| LoadingApiProject
	| UploadingSourceImage
	| UploadedSourceImage
	| UploadSourceImageError
	| CreatingApiProject
	| CreateApiProjectError
	| SavedToBackend
	| UpdatingInBackend
	| UpdatingError;

function isBackendCommittedSaveState(
	state: SaveState,
): state is SavedToBackend | UpdatingInBackend | UpdatingError {
	return (
		state.type === "savedToBackend" ||
		state.type === "updatingToBackend" ||
		state.type === "updatingError"
	);
}

type State = {
	readonly openedProjectSaveState?: SaveState;
};

const initialState: State = {};

const uploadSource = createAsyncThunk<
	string,
	undefined,
	StoreThunkApiConfig<{ workspace: WorkspaceState }>
>(
	`${name}/uploadSource`,
	async (_, { getState, extra: { apiTempMediaStorage } }) => {
		const {
			workspace: { openProject },
		} = getState();
		if (!openProject) {
			throw new Error("No open project");
		}
		const {
			project: {
				sourceImage: { smallestEncodedImage },
			},
		} = openProject;
		// Note: We used to do optimisation here, however that resulted in a design
		// that looked different when you load it back in since the initial design
		// uses a different source image than subsequent loads.
		const tempName = `${uuid()}.${imageMimeTypeToFileExtension(smallestEncodedImage.mimeType)}`;
		return backOff(
			() =>
				apiTempMediaStorage.saveFile(
					tempName,
					smallestEncodedImage.mimeType,
					smallestEncodedImage.buffer,
				),
			{
				timeMultiple: 1.5,
			},
		);
	},
);

const duplicateProject = createAsyncThunk<
	FullSavedProject,
	undefined,
	StoreThunkApiConfig<{ workspace: WorkspaceState; saveProject: State }>
>(`${name}/duplicateProject`, async (_, { getState, extra: { apiClient } }) => {
	const {
		workspace: { openProject },
		saveProject: { openedProjectSaveState },
	} = getState();
	if (!openProject) {
		throw new Error("No open project");
	}
	const id = await waitUntil(() => {
		if (!openedProjectSaveState) {
			return false;
		}
		switch (openedProjectSaveState.type) {
			case "loadingApiProject":
			case "uploadedSourceImage":
			case "uploadingSourceImage":
			case "creatingApiProject":
				return false;
			case "uploadSourceImageError":
			case "createApiProjectError":
				throw new Error("No project to duplicate");
			case "savedToBackend":
			// Fallthrough
			case "updatingToBackend":
			// Fallthrough
			case "updatingError":
			// Fallthrough
			default:
				return openedProjectSaveState.id;
		}
	});

	const result = await apiClient.request<
		DuplicateProjectMutation,
		DuplicateProjectMutationVariables
	>(duplicateProjectMutation, {
		id,
	});
	if (!result) {
		throw new Error("Error saving");
	}
	return removeTypenames(result.duplicateProject);
});

function getMissingSourcesForOpenProject(
	getState: () => StoreThunkApiConfig<{ workspace: WorkspaceState }>["state"],
	{ treatLoadingAsAvailable }: { treatLoadingAsAvailable: boolean },
) {
	const { workspace } = getState();
	const project = workspace.openProject?.project;
	if (!project) {
		throw new Error("No open project");
	}

	return getMissingBuildSources(project.currentPicture, {
		backgroundMask:
			(treatLoadingAsAvailable && isOnceOffLoading(project.backgroundMask)) ||
			isOnceOffLoaded(project.backgroundMask),
		colorisation:
			(treatLoadingAsAvailable &&
				isOnceOffLoading(project.colorisationImage)) ||
			isOnceOffLoaded(project.colorisationImage),
		enhanceFaces:
			(treatLoadingAsAvailable &&
				isOnceOffLoading(project.enhanceFacesImage)) ||
			isOnceOffLoaded(project.enhanceFacesImage),
		faceBoundingBoxes: !!project.faceBoundingBoxes,
	});
}

function hasMissingBuildSource(
	getState: () => StoreThunkApiConfig<{ workspace: WorkspaceState }>["state"],
	{ treatLoadingAsAvailable }: { treatLoadingAsAvailable: boolean },
) {
	const m = getMissingSourcesForOpenProject(getState, {
		treatLoadingAsAvailable,
	});
	return Object.values(m).includes(true);
}

const waitForMissingSources = createAsyncThunk<
	void,
	undefined,
	StoreThunkApiConfig<{ workspace: WorkspaceState; saveProject: State }>
>(
	`${name}/waitForMissingSources`,
	async (_, { dispatch, extra: { apiClient }, getState }) => {
		const { workspace } = getState();
		const project = workspace.openProject?.project;
		if (!project) {
			console.error("No open project");
			return;
		}

		const originalMissingSources = getMissingSourcesForOpenProject(getState, {
			treatLoadingAsAvailable: true,
		});

		// None actually missing
		if (!Object.values(originalMissingSources).includes(true)) {
			return;
		}

		// Start loading status of missing
		if (originalMissingSources.backgroundMask) {
			dispatch(setBackgroundMaskImage(loadingOnceOffLoad));
		}
		if (originalMissingSources.enhanceFaces) {
			dispatch(setEnhanceFacesImage(loadingOnceOffLoad));
		}
		if (originalMissingSources.colorisation) {
			dispatch(setColorisationImage(loadingOnceOffLoad));
		}

		try {
			await backOff(
				async () => {
					const { saveProject } = getState();
					if (
						!saveProject.openedProjectSaveState ||
						typeof saveProject.openedProjectSaveState !== "object" ||
						!("id" in saveProject.openedProjectSaveState)
					) {
						console.error("No saved project");
						return;
					}

					// See if the url is ready in the backend
					const data = await apiClient.request<
						GetSavedDesignSourcesQuery,
						GetSavedDesignSourcesVariables
					>(getSavedDesignSourcesQuery, {
						id: saveProject.openedProjectSaveState.id,
					});
					const design = data.savedDesignById;

					const currentMissingSources = getMissingSourcesForOpenProject(
						getState,
						{ treatLoadingAsAvailable: false },
					);

					// TODO: Can be run in parallel
					if (
						currentMissingSources.backgroundMask &&
						design.backgroundMaskImageUrl
					) {
						const backgroundMask = await loadBitmapFromUrl(
							design.backgroundMaskImageUrl,
						);
						dispatch(
							setBackgroundMaskImage(
								loadedOnceOffLoad(createBitmapMaskFromBitmap(backgroundMask)),
							),
						);
					}
					if (
						currentMissingSources.colorisation &&
						design.colorisationImageUrl
					) {
						const colorisation = await loadBitmapFromUrl(
							design.colorisationImageUrl,
						);
						dispatch(setColorisationImage(loadedOnceOffLoad(colorisation)));
					}
					if (
						currentMissingSources.enhanceFaces &&
						design.enhanceFacesImageUrl
					) {
						const enhanceFaces = await loadBitmapFromUrl(
							design.enhanceFacesImageUrl,
						);
						dispatch(setEnhanceFacesImage(loadedOnceOffLoad(enhanceFaces)));
					}
					if (
						currentMissingSources.faceBoundingBoxes &&
						design.faceBoundingBoxes
					) {
						dispatch(setFaceBoundingBoxes(design.faceBoundingBoxes));
					}

					if (
						hasMissingBuildSource(getState, { treatLoadingAsAvailable: false })
					) {
						throw new Error("Not ready yet");
					}
				},
				{
					numOfAttempts: 200,
					timeMultiple: 1,
					startingDelay: 700,
					retry: (e) => e.message === "Not ready yet",
				},
			);
		} catch (e) {
			const loadError = errorOnceOffLoad(unknownToString(e));
			if (originalMissingSources.backgroundMask) {
				dispatch(setBackgroundMaskImage(loadError));
			}
			if (originalMissingSources.enhanceFaces) {
				dispatch(setEnhanceFacesImage(loadError));
			}
			if (originalMissingSources.colorisation) {
				dispatch(setColorisationImage(loadError));
			}
			throw e;
		}

		// Check for sources that were needed at the start and so we started loading,
		// but not needed now.
		// Must be a nicer way to manage all this.
		const endProject = getState().workspace.openProject?.project;
		if (!endProject) {
			return;
		}

		// Note: Mightn't work with concurrent waitForMissing actions
		if (
			originalMissingSources.backgroundMask &&
			isOnceOffLoading(endProject.backgroundMask)
		) {
			dispatch(setBackgroundMaskImage(pendingOnceOffLoad));
		}
		if (
			originalMissingSources.colorisation &&
			isOnceOffLoading(endProject.colorisationImage)
		) {
			dispatch(setColorisationImage(pendingOnceOffLoad));
		}
		if (
			originalMissingSources.enhanceFaces &&
			isOnceOffLoading(endProject.enhanceFacesImage)
		) {
			dispatch(setEnhanceFacesImage(pendingOnceOffLoad));
		}
	},
);

const createProjectInBackend = createAsyncThunk<
	{
		id: string;
		timestamp: UnixTimestamp;
		sourceImageKey: string;
		userType: SavedUserType;
	},
	undefined,
	StoreThunkApiConfig<{
		workspace: WorkspaceState;
		saveProject: State;
		user: UserState;
	}>
>(
	`${name}/createProjectInBackend`,
	async (_, { getState, extra: { apiClient } }) => {
		const {
			saveProject: { openedProjectSaveState },
			workspace: { openProject: currentOpenProject },
			user: { user },
		} = getState();
		if (!currentOpenProject) {
			throw new Error("No open project");
		}
		if (openedProjectSaveState?.type !== "creatingApiProject") {
			throw new Error(
				`Expecting source to be uploaded (was ${openedProjectSaveState?.type})`,
			);
		}
		const { project: currentProject, build } = currentOpenProject;
		const numberOfColours = numberOfBrickColours(build.brickCounts);

		const result = await apiClient.request<
			CreateProjectMutation,
			CreateProjectMutationVariables
		>(createProjectMutation, {
			input: {
				tempSourceImageKey: openedProjectSaveState.tempSourceKey,
				...projectToTransportProject(
					creatorProjectToCoreProject(currentProject),
					numberOfColours,
				),
			},
		});
		if (!result) {
			throw new Error("Error saving");
		}

		return {
			id: result.createProject.id,
			timestamp: currentOpenProject.project.updatedAt,
			sourceImageKey: result.createProject.sourceImageKey,
			userType: user.type === "signedIn" ? "signedIn" : "guest",
		};
	},
);

const createProject = createAsyncThunk<
	void,
	undefined,
	StoreThunkApiConfig<{
		workspace: WorkspaceState;
		saveProject: State;
		user: UserState;
	}>
>(`${name}/createProject`, async (_, { dispatch }) => {
	await dispatch(createProjectInBackend());
	dispatch(waitForMissingSources());
});

const switchGuestProjectToUser = createAsyncThunk<
	void,
	undefined,
	StoreThunkApiConfig<{ saveProject: State }>
>(
	`${name}/switchGuestProjectToUser`,
	async (_, { extra: { apiClient }, getState }) => {
		const {
			saveProject: { openedProjectSaveState },
		} = getState();
		if (openedProjectSaveState?.type !== "savedToBackend") {
			throw new Error("Project not saved");
		}

		const result = await apiClient.request<
			SwitchGuestProjectToUserMutation,
			SwitchGuestProjectToUserVariables
		>(switchGuestProjectToUserMutation, {
			id: openedProjectSaveState.id,
		});
		if (!result) {
			throw new Error("Error saving");
		}
	},
);

const updateSaveOpenProject = createAsyncThunk<
	UnixTimestamp,
	undefined,
	StoreThunkApiConfig<{ workspace: WorkspaceState; saveProject: State }>
>(
	`${name}/updateSaveOpenProject`,
	async (_, { extra: { apiClient }, dispatch, getState }) => {
		const {
			workspace: { openProject },
			saveProject: { openedProjectSaveState },
		} = getState();
		if (!openProject) {
			throw new Error("No open project");
		}
		if (openedProjectSaveState?.type !== "updatingToBackend") {
			throw new Error(
				`Expected different project state (got ${openedProjectSaveState?.type})`,
			);
		}

		if (import.meta.env.NODE_ENV === "development") {
			console.time("Converting project to transport took");
		}
		const numberOfColours = numberOfBrickColours(openProject.build.brickCounts);
		const transportProject = projectToTransportProject(
			creatorProjectToCoreProject(openProject.project),
			numberOfColours,
		);
		if (import.meta.env.NODE_ENV === "development") {
			console.timeEnd("Converting project to transport took");
		}
		const result = await apiClient.request<
			UpdateProjectMutation,
			UpdateProjectMutationVariables
		>(updateProjectMutation, {
			input: {
				id: openedProjectSaveState.id,
				...transportProject,
			},
		});
		if (!result) {
			throw new Error("Error saving");
		}

		dispatch(waitForMissingSources());

		return openProject.project.updatedAt;
	},
);

const slice = createSlice({
	name,
	initialState,
	reducers: {
		clearProjectSave: () => initialState,
	},
	extraReducers: {
		[uploadSource.pending.type]: (state: Draft<State>) => {
			state.openedProjectSaveState = {
				type: "uploadingSourceImage",
			};
		},
		[uploadSource.fulfilled.type]: (
			state: Draft<State>,
			action: Draft<ReturnType<(typeof uploadSource)["fulfilled"]>>,
		) => {
			state.openedProjectSaveState = {
				type: "uploadedSourceImage",
				tempSourceKey: action.payload,
			};
		},
		[uploadSource.rejected.type]: (state: Draft<State>) => {
			state.openedProjectSaveState = { type: "uploadSourceImageError" };
		},
		[createProjectInBackend.pending.type]: (state: Draft<State>) => {
			const saveState = state.openedProjectSaveState;
			if (saveState?.type !== "uploadedSourceImage") {
				throw new Error("Expected upload to be complete");
			}
			state.openedProjectSaveState = {
				type: "creatingApiProject",
				tempSourceKey: saveState.tempSourceKey,
			};
		},
		[createProjectInBackend.fulfilled.type]: (
			state: Draft<State>,
			action: Draft<ReturnType<(typeof createProjectInBackend)["fulfilled"]>>,
		) => {
			state.openedProjectSaveState = {
				type: "savedToBackend",
				id: action.payload.id,
				sourceImageKey: action.payload.sourceImageKey,
				updatedAt: action.payload.timestamp,
				userType: action.payload.userType,
			};
		},
		[createProjectInBackend.rejected.type]: (state: Draft<State>) => {
			if (state.openedProjectSaveState?.type !== "creatingApiProject") {
				throw new Error(
					`Invalid state, got ${state.openedProjectSaveState?.type}`,
				);
			}
			state.openedProjectSaveState = {
				type: "createApiProjectError",
				tempSourceKey: state.openedProjectSaveState.tempSourceKey,
			};
		},
		[updateSaveOpenProject.pending.type]: (state: Draft<State>) => {
			const { openedProjectSaveState } = state;
			if (
				openedProjectSaveState?.type !== "updatingToBackend" &&
				openedProjectSaveState?.type !== "savedToBackend" &&
				openedProjectSaveState?.type !== "updatingError"
			) {
				throw new Error(
					`Invalid state (got ${openedProjectSaveState?.type ?? ""})`,
				);
			}

			state.openedProjectSaveState = {
				type: "updatingToBackend",
				sourceImageKey: openedProjectSaveState.sourceImageKey,
				previousUpdatedAt:
					openedProjectSaveState.type === "updatingToBackend"
						? openedProjectSaveState.previousUpdatedAt
						: openedProjectSaveState.updatedAt,
				userType: openedProjectSaveState.userType,
				id: openedProjectSaveState.id,
			};
		},
		[updateSaveOpenProject.fulfilled.type]: (
			state: Draft<State>,
			action: Draft<ReturnType<(typeof updateSaveOpenProject)["fulfilled"]>>,
		) => {
			if (
				state.openedProjectSaveState?.type !== "updatingToBackend" &&
				state.openedProjectSaveState?.type !== "savedToBackend"
			) {
				console.warn(
					`Invalid state, expected updatingToBackend but got ${state.openedProjectSaveState?.type}`,
				);
				return;
			}

			state.openedProjectSaveState = {
				type: "savedToBackend",
				sourceImageKey: state.openedProjectSaveState.sourceImageKey,
				userType: state.openedProjectSaveState.userType,
				id: state.openedProjectSaveState.id,
				updatedAt: action.payload,
			};
		},
		[updateSaveOpenProject.rejected.type]: (
			state: Draft<State>,
			action: ReturnType<(typeof updateSaveOpenProject)["rejected"]>,
		) => {
			if (state.openedProjectSaveState?.type !== "updatingToBackend") {
				throw new Error(
					`Invalid state, expected updatingToBackend but got ${state.openedProjectSaveState?.type}`,
				);
			}
			state.openedProjectSaveState = {
				type: "updatingError",
				id: state.openedProjectSaveState.id,
				sourceImageKey: state.openedProjectSaveState.sourceImageKey,
				updatedAt: state.openedProjectSaveState.previousUpdatedAt,
				userType: state.openedProjectSaveState.userType,
			};
			sentryCaptureMessage(JSON.stringify(action.error));
		},
		[signOut.fulfilled.type]: () => initialState,
		[switchGuestProjectToUser.fulfilled.type]: (state: Draft<State>) => {
			if (
				!state.openedProjectSaveState ||
				!("userType" in state.openedProjectSaveState)
			) {
				throw new Error("Invalid state, expected state with user type");
			}
			state.openedProjectSaveState.userType = "signedIn";
		},
		[loadSavedProject.pending.type]: (state: Draft<State>) => {
			state.openedProjectSaveState = {
				type: "loadingApiProject",
			};
		},
		[loadSavedProject.fulfilled.type]: (
			state: Draft<State>,
			action: Draft<ReturnType<(typeof loadSavedProject)["fulfilled"]>>,
		) => {
			state.openedProjectSaveState = {
				type: "savedToBackend" as const,
				...action.payload,
				userType: "signedIn",
			};
		},
		[loadSavedProject.rejected.type]: (state: Draft<State>) => {
			state.openedProjectSaveState = undefined;
		},
	},
});

export type { State };
export const { clearProjectSave } = slice.actions;
export {
	createProject,
	duplicateProject,
	updateSaveOpenProject,
	isBackendCommittedSaveState,
	uploadSource,
	switchGuestProjectToUser,
};
export default slice.reducer;
